import md from '../../../../images/team/md.jpeg'
import gm from '../../../../images/team/adekunle.jpeg'
import charity from '../../../../images/team/charity.jpeg'
import nancy from '../../../../images/team/nancy.jpeg'
import idris from '../../../../images/team/idris.jpeg'
import chikezie from '../../../../images/team/chikezie.jpeg'
import cosmos from '../../../../images/team/cosmos.jpg'
import md2 from '../../../../images/team/md.webp'
import gm2 from '../../../../images/team/adekunle.webp'
import charity2 from '../../../../images/team/charity.webp'
import nancy2 from '../../../../images/team/nancy.webp'
import idris2 from '../../../../images/team/idris.webp'
import chikezie2 from '../../../../images/team/chikezie.webp'
import cosmos2 from '../../../../images/team/cosmos.webp'


export const teamData = [
    {
        id:1,
        name:'Otunba Lekan Ewenla M.CIoD',
        position:'MANAGING DIRECTOR/CEO',
        image:md,
        image2:md2
    },
    {
        id:2,
        name:'Kunle Ewenla',
        position:"GM, INT'L OPS/BUS ADV ",
        image:gm,
        image2:gm2
    },
    {
        id:3,
        name:'Charity Ogbonna',
        position:'MANAGER, MQA',
        image:charity,
        image2:charity2
    },
    {
        id:4,
        name:'Nancy Omogui',
        position:'HEAD, ADMIN',
        image:nancy,
        image2:nancy2
    },
    {
        id:5,
        name:'Idris Shuayb',
        position:'MANAGER, FINANCE',
        image:idris,
        image2:idris2
    },
    {
        id:6,
        name:'Chikezie Oguamanam',
        position:' Ag. HEAD, MARKETING',
        image:chikezie,
        image2:chikezie2
    },
    {
        id:7,
        name:'Cosmos Michael',
        position:' HEAD, RELATIONSHIP MANAGEMENT',
        image:cosmos,
        image2:cosmos2
    }
    
]