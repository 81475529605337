export const brands = [
    {
        id:0,
        brand:'ndlea',
        image:'assets/images/partners/ndlea.png',
        image2:'assets/images/partners/ndlea.webp',
    },{
        id:1,
        brand:'niepa',
        image:'assets/images/partners/niepa.jpeg',
        image2:'assets/images/partners/niepa.webp'
    },{
        id:2,
        brand:'nrc',
        image:'assets/images/partners/nrc.jpeg',
        image2:'assets/images/partners/nrc.webp',
    },{
        id:3,
        brand:'body of benchers',
        image:'assets/images/partners/bodyofbenchers.jpeg',
        image2:'assets/images/partners/bodyofbenchers.webp',
    },{
        id:4,
        brand:'fedekowe',
        image:'assets/images/partners/fedekowe.jpeg',
        image2:'assets/images/partners/fedekowe.webp',
    },{
        id:5,
        brand:'ministry of defence',
        image:'assets/images/partners/minofdef.png',
        image2:'assets/images/partners/minofdef.webp',
    },{
        id:6,
        brand:'ministry of agric',
        image:'assets/images/partners/nigagric.jpeg',
        image2:'assets/images/partners/nigagric.webp',
    },{
        id:7,
        brand:'feduniotuoke',
        image:'assets/images/partners/feduniotuoke.png',
        image2:'assets/images/partners/feduniotuoke.webp',
    },{
        id:8,
        brand:'seamhealth',
        image:'assets/images/partners/seamhealth.jpeg',
        image2:'assets/images/partners/seamhealth.webp',
    },{
        id:9,
        brand:'Association-of-Nigerian-Women-Business-Network-ANWBN',
        image:'assets/images/partners/Association-of-Nigerian-Women-Business-Network-ANWBN.jpg',
        image2:'assets/images/partners/Association-of-Nigerian-Women-Business-Network-ANWBN.webp',
    },{
        id:10,
        brand:'choiceclouds-logo-blue-text',
        image:'assets/images/partners/choiceclouds-logo-blue-text.png',
        image2:'assets/images/partners/choiceclouds-logo-blue-text.webp',
    },{
        id:11,
        brand:'County_finance_Logo',
        image:'assets/images/partners/County_finance_Logo.png',
        image2:'assets/images/partners/County_finance_Logo.webp',
    },{
        id:12,
        brand:'DeepCover',
        image:'assets/images/partners/DeepCover.png',
        image2:'assets/images/partners/DeepCover.webp',
    },{
        id:13,
        brand:'FastCubic',
        image:'assets/images/partners/FastCubic.png',
        image2:'assets/images/partners/FastCubic.webp',
    },{
        id:14,
        brand:'FederalUHSOtukpo',
        image:'assets/images/partners/FederalUHSOtukpo.jpg',
        image2:'assets/images/partners/FederalUHSOtukpo.webp',
    },{
        id:15,
        brand:'FHIS',
        image:'assets/images/partners/FHIS.png',
        image2:'assets/images/partners/FHIS.webp',
    },{
        id:16,
        brand:'FMCKeffi',
        image:'assets/images/partners/FMCKeffi.png',
        image2:'assets/images/partners/FMCKeffi.webp',
    },{
        id:17,
        brand:'fUAZ',
        image:'assets/images/partners/fUAZ.png',
        image2:'assets/images/partners/fUAZ.webp',
        
    },{
        id:18,
        brand:'FUD',
        image:'assets/images/partners/FUD.png',
        image2:'assets/images/partners/FUD.webp',
    },{
        id:19,
        brand:'GADOL-LOGO',
        image:'assets/images/partners/GADOL-LOGO.png',
        image2:'assets/images/partners/GADOL-LOGO.webp',
    },{
        id:20,
        brand:'IHVNLOGO',
        image:'assets/images/partners/IHVNLOGO.png',
        image2:'assets/images/partners/IHVNLOGO.webp',
    },{
        id:21,
        brand:'Latins',
        image:'assets/images/partners/Latins.jpg',
        image2:'assets/images/partners/Latins.webp',
    },{
        id:22,
        brand:'LFMP_Logo',
        image:'assets/images/partners/LFMP_Logo.png',
        image2:'assets/images/partners/LFMP_Logo.webp',
    },{
        id:23,
        brand:'logo2',
        image:'assets/images/partners/logo2.png',
        image2:'assets/images/partners/logo2.webp',
    },{
        id:24,
        brand:'Ministry of Interior',
        image:'assets/images/partners/MinistryofInterior.png',
        image2:'assets/images/partners/MinistryofInterior.webp',
    },{
        id:25,
        brand:'Oau_logo',
        image:'assets/images/partners/Oau_logo.png',
        image2:'assets/images/partners/Oau_logo.webp',
    },{
        id:26,
        brand:'Uni Agric Zuru',
        image:'assets/images/partners/UniAgricZuru.png',
        image2:'assets/images/partners/UniAgricZuru.webp',
    },{
        id:27,
        brand:'uniben',
        image:'assets/images/partners/uniben-logo1.png',
        image2:'assets/images/partners/uniben-logo1.webp',
    },
]