import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getPosts } from '../../redux/reducers/postReducer';
import { getCategories } from '../../redux/reducers/categoryReducer';
import { getFImages } from '../../redux/reducers/fImgReducer';
import PlaceholderImage from "../../../images/placeholder.png";
import ProgressiveImg from '../ProgressiveImg';

const Blog = () => {
  const dispatch = useDispatch();

  const { postItems} = useSelector((state)=> state.posts)
  const { categoryItems } = useSelector((state)=> state.categories)
  const { imgItems } = useSelector((state)=> state.imgUrl)

  useEffect(()=>{
    dispatch(getPosts());
  }, [dispatch])

  useEffect(()=>{
    dispatch(getCategories());
  },[dispatch])



  useEffect(()=>{
    dispatch(getFImages());
  },[dispatch])


 

  const limitedPosts = postItems ? postItems.slice(0, 3) : [];
  const limitedImg = imgItems ? imgItems.slice(0, 3) : [];
  
  
 
  
  return (
    <section className="blog-section service-section pt-100 z-1 p-r bg_cover pb-100 pt-70 p-r z-1"
      style={{ backgroundImage: "url(assets/images/bg/blog.jpg)" }}
    >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-10">
              {/*=== Common Heading ===*/}
              <div className="section-title text-center mb-60 wow fadeInDown">
                <h2>Catch Up With Exciting News &amp; Blog Posts</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {limitedPosts.map((post, index)=>{
              const inputDateString = post.date;
              const date = new Date(inputDateString);
              const limitedTitle = post.title.rendered.slice(0, 25) +"...";


              const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
              ];
              
              const day = date.getDate().toString().padStart(2, '0');
              const month = months[date.getMonth()];
              const year = date.getFullYear();
              
              const formattedDate = `${day}/${month}/${year}`;
              return (
                <div className="col-xl-4 col-md-6 col-sm-12" key={post.id}>
                  {/*=== Blog Post Item ===*/}
                  <div
                    className="blog-post-item-one mb-40 wow fadeInUp"
                    data-wow-delay=".2s"
                  >
                    <div className="post-thumbnail">
                     
                          { limitedImg[index] && post.title && (



                                <ProgressiveImg
                                src={limitedImg[index].link}
                                placeholderSrc={PlaceholderImage}
                                alt={post.title.rendered }
                                width="1080"
                                height="1350"
                              />

                            

                         
                          )}
                          <span className="post-date">
                            <a href={`/posts/${post.slug}`}>
                              {formattedDate}
                            </a>
                          </span>

                    </div>
                    <div className="entry-content">
                      <div className="post-meta">
                        <ul>
                          {post.categories.map((categoryId) => (
                            <li key={categoryId}> 
                              {categoryItems.find(cat => cat.id === categoryId)?.name} 
                            </li>
                          ))}
                        </ul>
                      </div>
                      <h5 className="title">
                        <Link to={`/posts/${post.slug}`}>
                          <span>{limitedTitle}</span>
                        </Link>
                      </h5>
                      {/* <div dangerouslySetInnerHTML={{ __html: limitedWords + '...' }} /> */}
                      <Link to={`/posts/${post.slug}`}>
                        <span className="btn-link">Read More</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )              
            })}
          </div>
          <div className="row">
            <div className="col-lg-12">
              {/*=== Blog Button ===*/}
              <div
                className="blog-button text-center wow fadeInUp"
                data-wow-delay=".5s"
              >
                <Link to="/news">
                  <span className="main-btn btn-outline">View More News</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}


export default Blog