export default [
    {
        id:0,
        hospital:'Asher_UHMS',
        image:'assets/images/hospitals/Asher_UHMS.png',
        image2:'assets/images/hospitals/Asher_UHMS.webp',
    },{
        id:1,
        hospital:'Eko_UHMS',
        image:'assets/images/hospitals/Eko_UHMS.png',
        image2:'assets/images/hospitals/Eko_UHMS.webp',
    },{
        id:2,
        hospital:'guinea_savannah_uhms',
        image:'assets/images/hospitals/guinea_savannah_uhms.png',
        image2:'assets/images/hospitals/guinea_savannah_uhms.webp',
    },{
        id:3,
        hospital:'limi_uhms',
        image:'assets/images/hospitals/limi_uhms.png',
        image2:'assets/images/hospitals/limi_uhms.webp',
    },{
        id:4,
        hospital:'nisa_uhms',
        image:'assets/images/hospitals/nisa_uhms.png',
        image2:'assets/images/hospitals/nisa_uhms.webp',
    },{
        id:5,
        hospital:'PAMO_UHMS',
        image:'assets/images/hospitals/PAMO_UHMS.png',
        image2:'assets/images/hospitals/PAMO_UHMS.webp',
    },{
        id:6,
        hospital:'TCH-for-production',
        image:'assets/images/hospitals/TCH-for-production.png',
        image2:'assets/images/hospitals/TCH-for-production.webp',
    },{
        id:7,
        hospital:'ud_uhms',
        image:'assets/images/hospitals/ud_uhms.png',
        image2:'assets/images/hospitals/ud_uhms.webp',
    }
]